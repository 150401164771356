/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import Container from "../components/layout/Container"
import SEO from "../components/utility/SEO"

import HeroHeader from "../components/common/HeroHeader"
import LogoRoll from "../components/common/LogoRoll"
import PageContent from "../components/common/PageContent"
import LandingBenefits from "../components/content/LandingBenefits"
import CTABlock from "../components/common/CTABlock"

const LaunchLandingPage = ({ data: { prismicLaunchLandingPage } }) => {
  const { data } = prismicLaunchLandingPage

  return (
    <Layout>
      <SEO
        title={data.title.text}
        description={data.meta_description.text}
        canonicalUrl={data.canonical_url.text}
      />
      <HeroHeader
        heading={data.hero_heading.text}
        introduction={data.hero_text.text}
        CTAButtonLabel={data.header_cta_button_label.text}
        CTASubText={data.header_cta_sub_text.text}
      />
      <Container>
        <LogoRoll
          heading={data.reward_partners_heading.text}
          subHeading={data.reward_partners_sub_heading.text}
          logos={data.reward_partner_logos.map(({ name, image }) => ({
            name: name.text,
            image: image.url,
          }))}
        />
      </Container>
      <LandingBenefits
        brokerHeading={data.broker_benefits_heading}
        brokerBenefits={data.broker_benefits}
        customerHeading={data.customer_benefits_heading}
        customerBenefits={data.customer_benefits}
        ctaButtonLabel={data.benefits_cta_label}
        ctaSubLabel={data.benefits_cta_sub_label}
      />
      <CTABlock
        heading={data.cta_heading.text}
        text={data.cta_text.text}
        buttonLabel={data.cta_button_label.text}
        subText={data.cta_sub_text.text}
      />
    </Layout>
  )
}

export default LaunchLandingPage

export const pageQuery = graphql`
  query LandingPageQuery {
    prismicLaunchLandingPage {
      data {
        title {
          text
        }
        meta_description {
          text
        }
        canonical_url {
          text
        }
        hero_heading {
          text
        }
        hero_text {
          text
        }
        header_cta_button_label {
          text
        }
        header_cta_sub_text {
          text
        }
        reward_partners_heading {
          text
        }
        reward_partners_sub_heading {
          text
        }
        reward_partner_logos {
          name {
            text
          }
          image {
            url
          }
        }
        broker_benefits_heading {
          text
        }
        broker_benefits {
          benefit {
            text
          }
        }
        customer_benefits_heading {
          text
        }
        customer_benefits {
          benefit {
            text
          }
        }
        benefits_cta_label {
          text
        }
        benefits_cta_sub_label {
          text
        }
        cta_heading {
          text
        }
        cta_text {
          text
        }
        cta_button_label {
          text
        }
        cta_sub_text {
          text
        }
      }
    }
  }
`
