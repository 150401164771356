import React from "react"
import Container from "../../layout/Container"
import CTAButton from "../../common/CTAButton"
import Styles from "./Styles"

const LandingBenefits = ({
  brokerHeading,
  brokerBenefits,
  customerHeading,
  customerBenefits,
  ctaButtonLabel,
  ctaSubLabel,
}) => {
  return (
    <Container>
      <Styles.LandingBenefits>
        <div>
          <h3>{brokerHeading.text}</h3>
          <ul>
            {brokerBenefits.map(({ benefit }) => (
              <li>{benefit.text}</li>
            ))}
          </ul>
        </div>
        <div>
          <h3>{customerHeading.text}</h3>
          <ul>
            {customerBenefits.map(({ benefit }) => (
              <li>{benefit.text}</li>
            ))}
          </ul>
        </div>
        <CTAButton label={ctaButtonLabel.text} text={ctaSubLabel.text} />
      </Styles.LandingBenefits>
    </Container>
  )
}

export default LandingBenefits
