import styled from "styled-components"

const LandingBenefits = styled.section`
  max-width: ${({ theme }) => theme.space[15]};
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.space[6]} ${theme.space[3]}`};
  display: grid;
  grid-gap: ${({ theme }) => theme.space[6]};

  ul {
    display: grid;
    grid-gap: ${({ theme }) => theme.space[3]};
  }
`

export default {
  LandingBenefits,
}
